* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.filled {
  font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  font-family: 'Karla, sans-serif !important';
  transition: background-color 9999s ease-in-out 0s;
}

.scrollable-content::-webkit-scrollbar {
  width: 16px;
  border-radius: 100vw;
  background-color: transparent;
}

.scrollable-content::-webkit-scrollbar-track {
  background-color: transparent; /* Set the background color of the track */
}

.scrollable-content::-webkit-scrollbar-thumb {
  background-color: transparent; /* Set the color of the thumb */
}

.scrollable-content:hover::-webkit-scrollbar-thumb {
  background-color: #e4e8f2;
  border: 0.25em solid #f7f9fc;
  border-radius: 100vw;
}

.scrollable-content-always-show::-webkit-scrollbar {
  width: 16px;
  border-radius: 100vw;
  background-color: transparent;
}

.scrollable-content-always-show::-webkit-scrollbar-track {
  background-color: transparent; /* Set the background color of the track */
}

.scrollable-content-always-show::-webkit-scrollbar-thumb {
  background-color: #e4e8f2;
  border: 0.25em solid #f7f9fc;
  border-radius: 100vw;
}

.MuiPickersPopper-root > .MuiPaper-root {
  display: flex;
  padding: 8px 0px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 16px;
  border: 1px solid #32353c;
  background-color: #fff;
}

.MuiDateCalendar-root > div.MuiPickersCalendarHeader-root {
  display: flex;
  height: 64px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.MuiDateCalendar-root
  > div.MuiPickersCalendarHeader-root
  > div:nth-of-type(1)
  > div
  > div {
  color: var(--shades-grey-6, #464b53);
  text-align: center;
  font-family: Karla;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
}

.MuiDateCalendar-root > div.MuiPickersFadeTransitionGroup-root {
  display: flex;
  padding: 0px 12px 4px 12px;
  flex-direction: column;
  align-items: flex-end;
}

.MuiDayCalendar-header > span {
  display: flex;
  height: 48px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #202020;
  text-align: center;

  /* Headings/H6/Regular */
  font-family: Karla;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.MuiPickersDay-root,
.MuiPickersDay-root:not(.Mui-selected) {
  color: #69707b !important;
  text-align: center;
  font-family: Karla !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 24px !important; /* 150% */
  border: unset !important;
}

.MuiPickersDay-root:hover {
  background-color: #d3ecf6 !important;
  color: #464b53 !important;
}

.MuiPickersDay-root:focus {
  background-color: transparent !important;
}

.MuiPickersDay-root.Mui-selected {
  background-color: #0053ff !important;
  color: #fff !important;
}

.MuiPickersDay-root.Mui-selected:hover {
  background-color: #0b25b0 !important;
  color: #fff !important;
}

.MuiPickersDay-today {
  background-color: #eaf0ffa3 !important;
}

.MuiPickersYear-yearButton {
  color: #69707b !important;
  text-align: center;
  font-family: Karla !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 24px !important; /* 150% */
  border: unset !important;
}

.MuiPickersYear-yearButton.Mui-selected {
  background-color: #0053ff !important;
  color: #fff !important;
}

.MuiYearCalendar-root {
  width: auto;
  padding: 0px;
}

.PhoneInput {
  width: 100%;
  height: 40px;
  color: #202020;
  border-radius: 14px !important;
  border: 1px solid #32353c;
}

.PhoneInput > div.PhoneInputCountry {
  margin-left: 12px;
}

.PhoneInput > div.flag-dropdown {
  height: 40px;
  background-color: transparent;
}

.PhoneInput > input.PhoneInputInput {
  height: 100%;
  border: 0px;
  border-radius: 14px !important;
  font-family: Karla !important;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  padding-left: 6px;
}

.PhoneInput.PhoneInput--focus > input.PhoneInputInput:focus {
  outline: none;
  caret-color: #0053ff;
}

.PhoneInput:focus-within {
  border-color: #0053ff !important;
  border-width: 1px !important;
}

.PhoneInput > div.PhoneInputCountry > div.PhoneInputCountrySelectArrow {
  margin-left: 10px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  font-family: 'Karla, sans-serif !important';
  transition: background-color 9999s ease-in-out 0s;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Define the animation */
@keyframes underline {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* Apply the animation class */
.underline-animation {
  display: inline-block;
  position: relative;
  text-decoration: none;
}

.underline-animation::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: red;
  animation: underline 1s ease forwards;
}

.MuiPaginationItem-textPrimary.Mui-selected {
  background-color: #d3ecf6 !important;
  color: #464b53 !important;
}

.MuiTablePagination-selectLabel {
  color: #949ca9 !important;
  font-family: 'Karla';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.2px;
}

.MuiPaginationItem-text {
  color: #949ca9 !important;
  font-family: 'Karla';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.2px;
}

.MuiDataGrid-footerContainer {
  border: 0 !important;
}

.timezoneSelect > div > div {
  border: 1px solid #202020 !important;
  border-radius: 14px !important;
  background-color: #f1fafe;
  z-index: 999999;
  position: initial !important;
}

[id^='react-select-'][id$='-listbox'] {
  background-color: white !important;
  border: 0 !important;
}

.timezoneSelect > div > div > div > div {
  color: #464b53 !important;
  font-family: 'Karla';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 133.333% */
  letter-spacing: 0.2px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

/* Define the animation */
@keyframes focusedanimation {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.focused {
  display: inline-block;
  position: relative;
  text-decoration: none;
}

.focused::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: cornflowerblue;
  animation: focusedanimation 1s ease forwards;
}
