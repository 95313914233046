.MuiDataGrid-columnHeaderTitle {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #69707b;
}

.MuiDataGrid-cellContent {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #464b53;
}
.MuiDataGrid-virtualScroller {
  overflow: hidden !important;
}

.MuiDataGrid-row:hover {
  cursor: pointer;
}

.MuiDataGrid-row.Mui-even:hover {
  background-color: #f2fafe !important;
}

.MuiCheckbox-root.Mui-checked {
  color: #0053ff !important;
}

.MuiRadio-root.Mui-checked {
  color: #0053ff !important;
}

.MuiDataGrid-columnHeaderTitleContainerContent > span {
  padding: 0px;
}

.MuiDataGrid-cell > span {
  padding: 0px;
}

.MuiDataGrid-columnHeaderTitleContainerContent > span > svg {
  width: 20px;
  height: 20px;
}

.MuiDataGrid-cell > span > svg {
  width: 20px;
  height: 20px;
}

.MuiDataGrid-columnHeader
  > div.MuiDataGrid-columnHeaderDraggableContainer
  > div.MuiDataGrid-menuIcon,
.MuiDataGrid-columnHeader > div.MuiDataGrid-columnSeparator {
  display: none !important;
}

.MuiDataGrid-iconButtonContainer {
  display: none !important;
}

.MuiDataGrid-virtualScrollerRenderZone
  > div.Mui-odd
  > div.MuiDataGrid-cell:nth-child(1) {
  box-sizing: border-box;
  border-style: solid;
  border-color: #d3ecf7;
  border-width: 1px 0px 1px 1px;
  border-radius: 24px 0px 0px 24px;
}

.MuiDataGrid-virtualScrollerRenderZone
  > div.Mui-odd
  > div.MuiDataGrid-cell:last-child {
  box-sizing: border-box;
  border-width: 1px 1px 1px 0px;
  border-style: solid;
  border-color: #d3ecf7;
  border-radius: 0px 24px 24px 0px;
}

.MuiDataGrid-virtualScrollerRenderZone
  > div.Mui-odd
  > div.MuiDataGrid-cell:not(:first-child):not(:last-child) {
  /* Styles for the middle cells */
  box-sizing: border-box;
  border-width: 1px 0;
  border-style: solid;
  border-color: #d3ecf7;
}

.MuiDataGrid-virtualScrollerRenderZone
  > div.Mui-even
  > div.MuiDataGrid-cell:nth-child(1) {
  box-sizing: border-box;
  border-style: solid;
  border-color: transparent;
  border-width: 1px 0px 1px 1px;
  border-radius: 24px 0px 0px 24px;
}

.MuiDataGrid-virtualScrollerRenderZone
  > div.Mui-even
  > div.MuiDataGrid-cell:last-child {
  box-sizing: border-box;
  border-width: 1px 1px 1px 0px;
  border-style: solid;
  border-color: transparent;
  border-radius: 0px 24px 24px 0px;
}

.MuiDataGrid-virtualScrollerRenderZone
  > div.Mui-even
  > div.MuiDataGrid-cell:not(:first-child):not(:last-child) {
  /* Styles for the middle cells */
  box-sizing: border-box;
  border-width: 1px 0;
  border-style: solid;
  border-color: transparent;
}

.MuiDataGrid-row:hover > div:nth-child(1) {
  box-sizing: border-box;
  border-style: solid !important;
  border-color: #0053ff !important;
  border-width: 1px 0px 1px 1px !important;
  border-radius: 24px 0px 0px 24px !important;
}

.MuiDataGrid-row:hover > div:last-child {
  box-sizing: border-box;
  border-width: 1px 1px 1px 0px !important;
  border-style: solid !important;
  border-color: #0053ff !important;
  border-radius: 0px 24px 24px 0px !important;
}

.MuiDataGrid-row:hover > div:not(:first-child):not(:last-child) {
  box-sizing: border-box;
  border-width: 1px 0 !important;
  border-style: solid !important;
  border-color: #0053ff !important;
}

.MuiDataGrid-row.Mui-selected {
  background-color: #d3ecf7 !important;
}

.MuiDataGrid-row.Mui-selected:hover {
  background-color: #baddeb !important;
}

.MuiDataGrid-row.Mui-selected > div:nth-child(1) {
  box-sizing: border-box;
  border-style: solid !important;
  border-color: #0053ff !important;
  border-width: 1px 0px 1px 1px !important;
  border-radius: 24px 0px 0px 24px !important;
}

.MuiDataGrid-row.Mui-selected > div:last-child {
  box-sizing: border-box;
  border-width: 1px 1px 1px 0px !important;
  border-style: solid !important;
  border-color: #0053ff !important;
  border-radius: 0px 24px 24px 0px !important;
}

.MuiDataGrid-row.Mui-selected > div:not(:first-child):not(:last-child) {
  box-sizing: border-box;
  border-width: 1px 0 !important;
  border-style: solid !important;
  border-color: #0053ff !important;
}

.MuiDataGrid-row.Mui-selected:hover > div:nth-child(1) {
  box-sizing: border-box;
  border-style: solid !important;
  border-color: #0b25b0 !important;
  border-width: 1px 0px 1px 1px !important;
  border-radius: 24px 0px 0px 24px !important;
}

.MuiDataGrid-row.Mui-selected:hover > div:last-child {
  box-sizing: border-box;
  border-width: 1px 1px 1px 0px !important;
  border-style: solid !important;
  border-color: #0b25b0 !important;
  border-radius: 0px 24px 24px 0px !important;
}

.MuiDataGrid-row.Mui-selected:hover > div:not(:first-child):not(:last-child) {
  box-sizing: border-box;
  border-width: 1px 0 !important;
  border-style: solid !important;
  border-color: #0b25b0 !important;
}
